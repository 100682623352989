<template>
    <div class="upload">
        <el-upload name="uploadFile" :list-type="uploadParams.listType" :file-list="fileList" :disabled="uploadParams.disabled" ref="uploadComp"
                   :on-preview="handlePreview" :before-remove="beforeRemove" :on-remove="handleRemove" :before-upload="beforeUpload"
                   :accept="uploadParams.accept" :auto-upload="uploadParams.autoUpload" :on-change="uploadOnChange" :on-success="uploadOnSuccess"
                   :limit="uploadParams.limitNum" :on-exceed="handleExceed"
                   :multiple="uploadParams.multiple" action="" :show-file-list="uploadParams.showFileList" :http-request="doUpload">
            <el-button type="text" v-if="!uploadParams.disabled">{{uploadParams.title}}</el-button>
        </el-upload>
    </div>
    <el-drawer
            title="上传结果"
            v-model="uploadResultDrawer"
            direction="ltr"
            size="50%"
            destroy-on-close>
        <div style="margin-left: 5px;">
            <div v-html="resultMsg"></div>
        </div>
    </el-drawer>

    <el-dialog v-model="uploadDialogVisible" :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true" width="50%">
        <el-image
                  style="width: 100%; height: 100%" fit="cover"
                  :src="dialogImageUrl"
                  :preview-src-list="dialogImageUrls"
        >
        </el-image>
    </el-dialog>
</template>

<script>
    import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, inject} from 'vue';
    export default {
        name: "Upload",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let uploadOwner = inject('uploadOwner');
            const uploadComp=ref(null);
            let dataObj=reactive({
                resultMsg:"",
                uploadDialogVisible:false,//点击文件，让文件在弹出框中显示，主要应用于图片，showInDialog为true的时候，该参数的配置才有意义
                dialogImageUrl:'',//在弹出框中显示的文件
                dialogImageUrls:[],//点击文件之后，本次可以查看的文件数组集合
                fileList:[],
                curUploadNum:0,
                uploadParams:{
                    title:'上传',
                    multiple:true,
                    listType:'text',
                    showFileList:false,
                    showInDialog:false,
                    disabled:false,
                    autoUpload:true,
                    delRealFile:true,
                    // accept:'.jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF',
                    accept:'',
                    fileSize:10,
                    limitNum:5,
                    action:utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo")),
                },
                uploadResultDrawer:false
            });
            onMounted(()=>{
                //如果外部没有指定action，则采用默认的action，外部指定了上传action，采用指定的action
                let action=dataObj.uploadParams.action;
                if(context.attrs.action)action=context.attrs.action;
                dataObj.uploadParams = Object.assign({},dataObj.uploadParams, context.attrs,{action:action});
            });
            const beforeRemove=(file, fileList)=> {
                // return proxy.$confirm(`确定要删除 ${file.name} 吗?`);
                const isLarge = file.size / 1024 / 1024 < dataObj.uploadParams.fileSize
                if (isLarge) {
                    return proxy.$confirm(`确定要删除 ${file.name}吗?`,'删除提示',{
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (uploadOwner && uploadOwner.beforeRemove) {
                            let res=uploadOwner.beforeRemove(file, fileList);
                        }else{
                            proxy.$message.success("已移除");
                        }
                    });
                }
            }
            const handleRemove=async (file, fileList)=> {
                if(dataObj.curUploadNum>0)dataObj.curUploadNum--;
                const isLarge = file.size / 1024 / 1024 < dataObj.uploadParams.fileSize;
                if (!file.size || isLarge) {
                    if (uploadOwner && uploadOwner.handleRemove) {
                        await uploadOwner.handleRemove(file);
                    }else{
                        if(uploadOwner.removeUploadFile)await uploadOwner.removeUploadFile(file, fileList);
                        let delSuccess=true;
                        if(dataObj.uploadParams.delRealFile){//可以删除附件
                            let res = await utils.$$api.deleteFile({id:file.id});
                            if(res.result){
                                utils.$$tools.success({message: "操作成功"});
                            }else{
                                delSuccess=false;
                            }
                        }
                        if(delSuccess){
                            let index=dataObj.fileList.findIndex(item=>{
                                return item.id==file.id;
                            })
                            if(index!=-1)dataObj.fileList.splice(index, 1)
                        }
                        if(uploadOwner.afterRemoveUploadFile)await uploadOwner.afterRemoveUploadFile(file, fileList);
                    }
                }
            }
            const handlePreview=async (file)=>{
                if (uploadOwner && uploadOwner.handlePreview) {
                    await uploadOwner.handlePreview(file);
                }else{
                    if(dataObj.uploadParams.showInDialog){
                        dataObj.uploadDialogVisible=true;
                        dataObj.dialogImageUrl=file.url;
                    }
                }
            }
            const doUpload=async (params)=>{
                const loading = proxy.$loading({
                    lock: true,
                    text: '正在努力上传中，请耐心等候......',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let formData = new FormData(),file = params.file,fileType = file.type;
                // let isLt2M = file.size / 1024 / 1024 < 2;//是否小于2M
                //默认给了两个参数，如果外界要修改这两个参数，formData.set('uploadType','abcd');
                formData.append('commonFile',file);//上传的文件
                formData.append('uploadType',(dataObj.uploadParams.uploadType?dataObj.uploadParams.uploadType:'commonFile'));//其他参数
                if (uploadOwner && uploadOwner.buildUploadParams) {
                    await uploadOwner.buildUploadParams(formData,params,proxy);
                }
                //遍历FormData
                // for (const key of formData.keys()) {
                //     console.log("key:" + key + " value:" + formData.get(key));
                // }
                //这里配合axios中准备上传url的时候
                let temp={
                    formData:formData,
                    urlPre:dataObj.uploadParams.action,
                    url:'uploadFiles'
                }
                if(context.attrs.action){//如果自己指定了上传action，那么地址拼接采用如下方式
                    temp={
                        formData:formData,
                        url:context.attrs.action
                    }
                }
                utils.$$upload(temp).then(async (res)=>{
                    if(typeof res=='string')res=JSON.parse(res);
                    if (uploadOwner && uploadOwner.uploadResult) {//自定义上传结果处理
                        await uploadOwner.uploadResult(res);
                    }else{//组件默认结果处理
                        if(!res.result){//上传失败之后，打开drawer显示后台的错误消息
                            if(res.errorData)dataObj.resultMsg=res.errorData;
                            utils.$$tools.info({message: (res.msg?res.msg:"上传失败!")});
                            if(res.errorData)dataObj.uploadResultDrawer=true;
                        }else{
                            utils.$$tools.success({message: (res.msg?res.msg:"上传成功!")});
                            dataObj.curUploadNum=0;
                        }
                        if (uploadOwner && uploadOwner.afterResult) {//上传完成之后
                            await uploadOwner.afterResult(res,proxy);
                        }
                    }
                    loading.close();
                });
            }
            //是否禁用上传组件
            const disableUpload=(disabled)=>{
                dataObj.uploadParams.disabled=disabled;
            }
            //设置上传组件最多支持上传多少个附件
            const setLimitNum=(limitNum)=>{
                dataObj.uploadParams.limitNum=limitNum;
            }
            //当上传的文件超过指定的数量时候，提示超标，并且停止上传
            const handleExceed=(files, fileList)=>{
                proxy.$message.warning('最多上传'+dataObj.uploadParams.limitNum+'个文件');
            }
            const beforeUpload=(file)=>{
                if(uploadOwner && uploadOwner.beforeUpload && !uploadOwner.beforeUpload(file,proxy)){
                    return false;
                }
                const isLarge = file.size / 1024 / 1024 < dataObj.uploadParams.fileSize
                if (!isLarge) {
                    proxy.$message.error('上传文件必须是小于'+dataObj.uploadParams.fileSize+'M的图片!');
                    return false;
                }
                return true;
            }
            const uploadOnChange=(file, fileList)=>{
                dataObj.curUploadNum++;
            }
            const uploadOnSuccess=(response, file, fileList)=>{
                if(uploadOwner && uploadOwner.uploadOnSuccess && !uploadOwner.uploadOnSuccess(response, file, fileList,proxy)){
                    return false;
                }
            }
            return{
                ...toRefs(dataObj),uploadComp,doUpload,beforeRemove,handleRemove,handlePreview,disableUpload,setLimitNum,
                handleExceed,beforeUpload,uploadOnChange,uploadOnSuccess
            }
        }
    }
</script>

<style scoped>
.upload{
    width: 100%;
}
</style>