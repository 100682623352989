import {$$str} from './string';
import {$$const} from './const';
import E from "wangeditor";
import store from '@/store';
let ctx;

export const $$tools = {
    //加载main.js的时候，会调用该方法初始化ctx
    initCtx(app){
        ctx=app.config.globalProperties;
    },
    //从vuex中获取属性值
    getPropFromVuex(propName){
        return store.getters[propName];//如果页面在刷新还未呈现的时候，访问vuex会有问题,因为这个时候vuex的变量是默认值，可能还未从后台获取配置的值
    },
    configBox(options) {
        let title = "提示";
        let msgContent = "此操作将永久删除该条记录, 是否继续?";
        if (options.title) title = options.title;
        if (options.msgContent) msgContent = options.msgContent;
        return ctx.$confirm(msgContent, title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            if (options.fn) return options.fn();
        }).catch((e) => {
            ctx.$message({
                type: 'info',
                message: '已取消该操作'
            });
            if (options.cancelFn) options.cancelFn();
        });
    },
    success(options) {
        let defaultParam = {
            title: "提示",
            position: "bottom-right",
            message: "操作成功",
            duration: 3000
        }
        let params = Object.assign({}, defaultParam, options);
        ctx.$notify.success(params);
    },
    error(options) {
        let defaultParam = {
            title: "失败提示",
            position: "bottom-right",
            message: "操作失败",
            duration: 0
        }
        let params = Object.assign({}, defaultParam, options);
        ctx.$notify.error(params);
    },
    info(options) {
        let defaultParam = {
            title: "提示",
            position: "bottom-right",
            message: "消息提醒",
            dangerouslyUseHTMLString: true,
            duration: 3000
        }
        let params = Object.assign({}, defaultParam, options);
        ctx.$notify.info(params);
    },
    warning(options) {
        let defaultParam = {
            title: "警告",
            position: "bottom-right",
            message: "警告信息",
            dangerouslyUseHTMLString: true,
            duration: 3000
        }
        let params = Object.assign({}, defaultParam, options);
        ctx.$notify.warning(params);
    },
    //日期控件为范围选择的时候，默认范围
    defDateRange(options){
        return[{
            text: '当天',
            value: new Date()
        },{
            text: '最近一周',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end]
            })()
        }, {
            text: '最近一个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                return [start, end]
            })()
        }, {
            text: '最近三个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end]
            })()
        }]
    },
    //生成富文本编辑器wangEditor
    buildWangEditorField(options){
        // 配置 server 接口地址
        let aloneServerInfo=$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
        const editField = new E('#'+options.fieldId);
        editField.config.zIndex = 300;
        editField.config.uploadImgServer = aloneServerInfo+"/uploadFiles";
        editField.config.uploadFileName = 'editorPic';
        editField.config.uploadImgParams = {
            uploadType:'editorPic'
        }
        editField.config.uploadImgHeaders[$$const.baseVar.jfAccessToken]=sessionStorage.getItem($$const.baseVar.jfAccessToken)
        editField.config.height = 200;
        editField.create();
        editField.txt.clear();//销售单中，点击调价的时候，又会打开一个弹出框，这个时候会出现富文本编辑器重复嵌套的问题，这句话就是解决这个问题的
        return editField;
    }
};