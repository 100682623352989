//明细增加行事件
const ADDDETAILHANDLER = "addDetailHandler";
//增加行之前的检查事件，有且返回true则继续往下执行
const BEFOREADDHANDLER = "beforeAddHandler";
//表格自己的删除方法
const DELDETAILHANDLER = "delDetailHandler";
//删除行之前的检查事件，有且返回true则继续往下执行
const BEFOREDELHANDLER = "beforeDelHandler";
//删除行之后事件 
const AFTERDELHANDLER = "afterDelHandler";
//单击行事件
const CLICKTABLEROW = "clickTableRow";
//编辑行之前事件，返回true继续往下执行
const BEGANEDIT = "beganEdit";
//编辑已经显示，执行之后就可以操作了
const AFTERBEGANEDIT = "afterBeganEdit";
//表格数据加载完成
const GRIDLOADED = "gridLoaded";
//结束编辑之前
const BEFOREENDEDIT = "beforeEndEdit";
//行改变事件
const CURRENTCHANGE = "currentChange";
//清除表格数据之前事件方法
const BEFORECLEARDATA = "beforeClearData";
//清除表格数据之后事件方法
const AFTERCLEARDATA = "afterClearData";
//单击单元格事件
const CELLCLICK = "cellClick";
//双击单元格事件
const CELLDBLCLICK = "cellDblclick";
//上移事件
const UPHANDLER = "upHandler";
//下移事件
const DOWNHANDLER = "downHandler";
//构建标题
const BUILDTITLE = "buildTitle";
import {ref, reactive, toRefs, computed, defineComponent, onMounted, onBeforeMount, getCurrentInstance, watch, provide, inject} from 'vue';
const EditTableHelper = defineComponent({
    name: "EditTable",
    props: {
        detailParam: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);

        let EditTableOwner = inject('EditTableOwner');
        let dataObj=reactive({
            editParams: {idField: "F_ID",title:'明细数据',canAdd: true,canUp:false,canDown:false,readOnly: false,isShowFixCol: true,toolBar: [],showTitle:true,showToolBar:true},//明细表格的参数
            fields: [],//表格所有列
            insert: [], //新增的数据
            update: [], //修改的数据
            delete: [], //删除的数据
            detailTypeRef:null,
            detailType: props.detailParam.queryParam.detailType//外界调用EditTable的时候，肯定会传入queryParam（表格需要这个对象）,在queryParam里面指定明细表格名称
        })
        onBeforeMount(()=>{
            //把父组件的参数和自己本身的参数合并
            dataObj.editParams = Object.assign({autoLoad:false,tbId:dataObj.detailType},dataObj.editParams,props.detailParam);
        })
        onMounted(()=>{
            //表格所有列
            dataObj.fields=dataObj.detailTypeRef.tbCols.fields;
        })
        //表格头部的toolbar自定义按钮事件
        const clickHandler=(ev, param)=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if (EditTableOwner[ev]) {
                EditTableOwner[ev](param);
            } else {
                proxy[ev](param);
            }
        }
        //设置表格只读
        const setReadOnly=async (val)=>{
            dataObj.editParams.readOnly=val;
            await resetTableNoEdit();
        }
        //表格头部新增按钮处理事件
        const addDetailHandler=async ()=>{
            //可能有多个明细表格，比如用tab来展示，那么不同的表格肯定有不同的事件
            if (EditTableOwner[ADDDETAILHANDLER]) {
                EditTableOwner[ADDDETAILHANDLER](dataObj.detailType);
            } else {//父组建没有定义自己的新增事件，那么就用子组件默认的新增事件
                //根据表格列构造一个空行对象，方便拿来给新增行用，默认新增行可以编辑
                let row = {EDIT:true};
                dataObj.fields.forEach((field)=> {
                    row[field] = "";//给新增行row赋上表格的属性，这里的this就是row对象
                }, row);//传入row就是为了改变this
                //看父组建是否定义了新增之前的处理事件
                if (EditTableOwner[BEFOREADDHANDLER] && !EditTableOwner[BEFOREADDHANDLER](dataObj.detailType, row)) return;
                if (EditTableOwner[BEGANEDIT] && !EditTableOwner[BEGANEDIT](dataObj.detailType)) return false;
                //添加之前，把其它行状态全部置为不可编辑状态
                if(await resetTableNoEdit()){
                    dataObj.detailTypeRef.tbData.push(row);  //把新增的空行添加进表格显示
                }
            }
        }
        //表格头部删除按钮处理事件
        const delDetailHandler=(row, index)=>{//表格固定列的删除行事件
            let idField = dataObj.editParams.idField;
            //检查父组建是否有自己的删除事件，有则执行自己的删除事件
            if (EditTableOwner[DELDETAILHANDLER]) {
                EditTableOwner[DELDETAILHANDLER](dataObj.detailType, row, index);
            } else {
                //执行删除之前的事件
                if (EditTableOwner[BEFOREDELHANDLER] && !EditTableOwner[BEFOREDELHANDLER](dataObj.detailType, row, index)) return;
                //删除表格指定的行
                dataObj.detailTypeRef.tbData.splice(index, 1);
                if (row[idField]) {
                    dataObj.delete.push(row[idField]);
                }
                deleteFromInsert(row);
                deleteFromUpdate(row);
                //删除后事件
                if (EditTableOwner[AFTERDELHANDLER]) EditTableOwner[AFTERDELHANDLER](dataObj.detailType, row, index)
            }
        }
        //数据表格加载完毕事件
        const gridLoaded=(res)=>{
            resetArray();
            if (EditTableOwner[GRIDLOADED]) {
                EditTableOwner[GRIDLOADED](dataObj.detailType, res);
            } else {
                //加载表格，为表格初始化影藏字段EDIT,默认为false，表示该行现在没有处于编辑状态
                res.rows.forEach((row)=> {
                    row.EDIT = false;
                });
            }
        }
        //重置插入、更新、删除数组
        const resetArray=()=>{
            dataObj.insert=[];dataObj.update=[];dataObj.delete=[];
        }
        //向外抛行改变事件
        const currentChange=(options)=>{//表格行改变的时候，自动会调用EditTable的该方法，然后在该方法中继续向外抛
            if (EditTableOwner[CURRENTCHANGE]) {
                EditTableOwner[CURRENTCHANGE](dataObj.detailType, options);
            }
        }
        //点击行事件，把其它行变为不可编辑，把当前点击行变为可编辑状态
        const clickTableRow=async (options)=>{
            let flag=true;
            if (EditTableOwner[CLICKTABLEROW]) {
                EditTableOwner[CLICKTABLEROW](dataObj.detailType, options);
            } else {
                if (dataObj.editParams.readOnly) return; //如果表格只读，直接返回
                if (options.row.EDIT) return; //如果已经处于编辑状态，则不管了，直接返回
                //如果定义了beganEdit则调用beganEdit
                if (EditTableOwner[BEGANEDIT] && !await EditTableOwner[BEGANEDIT](dataObj.detailType, options)) return false;
                //如果beganEdit返回true，让变表格其它所有行为不可编辑状态
                flag=await resetTableNoEdit();
                if(flag)dataObj.detailTypeRef.tbData[options.row.rowIndex].EDIT = !options.row.EDIT;
            }
            if (flag && !dataObj.editParams.readOnly && EditTableOwner[AFTERBEGANEDIT]) EditTableOwner[AFTERBEGANEDIT](dataObj.detailType, options);
        }
        //把表格所有行处于不可编辑状态
        const resetTableNoEdit=async ()=>{
            let idField = dataObj.editParams.idField;
            let tbData = dataObj.detailTypeRef.tbData;
            for (let i = 0; i < tbData.length; i++) {
                if (tbData[i].EDIT) {
                    //如果某行处于编辑状态，则结束其编辑状态
                    if (EditTableOwner[BEFOREENDEDIT] && ! await EditTableOwner[BEFOREENDEDIT](dataObj.detailType, tbData[i], i)) return false;
                    tbData[i].EDIT = false;
                    //在结束编辑时，看是否有ID，有则放入更新数组，没有则放入新增数组。
                    if (tbData[i][idField]) {
                        dataObj.update.push(tbData[i]);
                    } else {
                        dataObj.insert.push(tbData[i]);
                    }
                }
            }
            dataObj.detailTypeRef.tbData = tbData;
            return true;
        }
        //获取表格引用
        const getTbInst=()=>{
            return dataObj.detailTypeRef;
        }
        //得到表格数据
        const getDetailData=()=>{//得到编辑表格数据
            return dataObj.detailTypeRef.tbData;
        }
        //清除表格数据
        const clearDetailData=async ()=>{
            let tbData=getDetailData();
            if (EditTableOwner[BEFORECLEARDATA] && !await EditTableOwner[BEFORECLEARDATA](dataObj.detailType, tbData)) return;
            tbData.forEach((row, index)=> {
                delDetailHandler(row,index);//触发删除行事件，因为父类可能需要在删除行的时候做一些操作
            });
            dataObj.update.length=0;//把更新、插入数组清空
            dataObj.insert.length=0;
            dataObj.detailTypeRef.clearTbData();
            if (EditTableOwner[AFTERCLEARDATA])EditTableOwner[AFTERCLEARDATA](dataObj.detailType,dataObj.delete)
        }
        //重新加载表格
        const reloadGrid=async(params)=>{
            // props.detailParam.queryParam = Object.assign(props.detailParam.queryParam, params);
            await dataObj.detailTypeRef.queryHandler(true, params);
        }
        //得到将要保存|更新|删除的数据
        const getSaveDetailData=async()=>{
            //先结束整个表格的编辑动作
            if(!await resetTableNoEdit())return null;
            return {
                insert: [...new Set(dataObj.insert)],//去重 考虑是否需要
                update: [...new Set(dataObj.update)],//去重
                delete: [...new Set(dataObj.delete)]//去重
            };
        }
        //删除insert数组中指定的项
        const deleteFromInsert=(row)=>{
            utils.$$str.delItemFromArr(dataObj.insert,row);
        }
        //删除update数组中指定的项
        const deleteFromUpdate=(row)=>{
            utils.$$str.delItemFromArr(dataObj.update,row);
        }
        //增加一行
        const addRow=(row)=>{
            dataObj.detailTypeRef.tbData.push(row);
            dataObj.insert.push(row);
        }
        //把某行放进新增数组中
        const setInsert=(row)=>{
            dataObj.insert.push(row);
        }
        //把某行放进修改数组中
        const setUpdate=(row)=>{
            dataObj.update.push(row);
        }
        //设置要后台删除行的id
        const setDelete=(id)=>{
            dataObj.delete.push(id);
        }
        //更新某行
        const setRowData=(row, index)=>{
            dataObj.detailTypeRef.tbData[index] = row;
        }
        //更新某行指定列的值
        const setCellData=(field,value, index)=>{
            dataObj.detailTypeRef.tbData[index][field]=value;
        }
        //单击单元格事件
        const cellClick=(row, column, cell, event)=>{
            if (EditTableOwner[CELLCLICK]) {
                EditTableOwner[CELLCLICK](row, column, cell, event);
            }
        }
        //单双击单元格事件
        const cellDblclick=(row, column, cell, event)=>{
            if (EditTableOwner[CELLDBLCLICK]) {
                EditTableOwner[CELLDBLCLICK](row, column, cell, event);
            }
        }
        //获取表格当前编辑行
        const getEditRow=()=>{
            let tbData = dataObj.detailTypeRef.tbData;
            return tbData.find((row,index,tbData)=>{
                return tbData[index].EDIT==true;
            });
        }
        //上移
        const upHandler=()=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if (EditTableOwner[UPHANDLER]) {
                EditTableOwner[UPHANDLER](dataObj.detailType);
            } else {
                let grid=getTbInst();
                let selection=grid.getSelection();
                if(selection.length==1){
                    let rowIndex=selection[0].rowIndex;
                    if(rowIndex!=0){
                        let data=getDetailData();
                        let moveItem = data[rowIndex];
                        data[rowIndex] = data[rowIndex-1];
                        data[rowIndex-1] = moveItem;
                    }
                }
            }
        }
        //下移
        const downHandler=()=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if (EditTableOwner[DOWNHANDLER]) {
                EditTableOwner[DOWNHANDLER](dataObj.detailType);
            } else {
                let grid=getTbInst();
                let selection=grid.getSelection();
                if(selection.length==1){
                    let rowIndex=selection[0].rowIndex;
                    let data=getDetailData();
                    if(rowIndex!=(data.length-1)){
                        let moveItem = data[rowIndex];
                        data[rowIndex] = data[rowIndex+1];
                        data[rowIndex+1] = moveItem;
                    }
                }
            }
        }
        //表格标题
        const buildTitle=(tbId,gridProxy)=>{
            if (EditTableOwner[BUILDTITLE]) {
               return EditTableOwner[BUILDTITLE](dataObj.detailType, proxy);
            }else{
                return dataObj.editParams.title;
            }
        }
        const getSummaries=(tbId,param)=>{
            if (EditTableOwner['getSummaries']) {
                return EditTableOwner['getSummaries'](tbId,param, proxy);
            }else{
                return [];
            }
        }
        return{
            ...toRefs(dataObj),clickHandler,setReadOnly,addDetailHandler,delDetailHandler,gridLoaded,resetArray,currentChange,
            clickTableRow,resetTableNoEdit,getDetailData,clearDetailData,reloadGrid,getSaveDetailData,deleteFromInsert,
            deleteFromUpdate,addRow,setInsert,setUpdate,setDelete,setRowData,setCellData,cellClick,cellDblclick,getTbInst,getEditRow,upHandler,downHandler,
            buildTitle,getSummaries
        }
    },

    components: {}
});
export default EditTableHelper;