<template>
    <div id="app">
        <router-view v-slot="{ Component }">
<!--            <transition :name="animationName" >-->
<!--                <keep-alive>-->
<!--                    <component :is="Component"/>-->
<!--                </keep-alive>-->
<!--            </transition>-->

            <keep-alive>
                <component :is="Component"/>
            </keep-alive>
        </router-view>
    </div>
</template>

<script>
    import { reactive,toRefs,defineComponent} from 'vue';
    export default defineComponent({
        name: 'App',
        setup(props,context){
            let dataObj=reactive({
                //fade-transform,el-zoom-in-center,el-fade-in
                animationName:!localStorage.getItem('routeAnimation')?'el-zoom-in-center':localStorage.getItem('routeAnimation')
            });
            return{
                ...toRefs(dataObj)
            }
        }
    });
</script>

<style>
    /*transition（router-view）自定义特效*/

    /*动画1：向下消失*/
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all 0.5s;
    }
    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-30px);
    }
    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }


    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    html,
    body,
    #app {
        width: 100%;
        height: 100%;
        overflow: hidden;
        perspective: 1000px;
    }

    #pageList {
        height: 100%;
    }
    .myRow {
        /*margin-bottom: 5px;*/
    }

    .myInput {
        width: 250px;
    }

    .myColTitle {
        text-align: right;
        line-height: 35px;
    }

    a {
        text-decoration: none;
    }

    /*滚动条样式*/
    ::-webkit-scrollbar {
        /*竖向滚动条的宽度*/
        width: 6px;
        /*横向滚动条的高度*/
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    .el-dialog.is-fullscreen {
        overflow: hidden !important;
    }

    /*让el-drawer出现滚动条，一般情况我们把el-drawer append到body上的，所以css写到全局里面，否则写到局部不生效*/
    .el-drawer {
        overflow: auto !important;
    }

    .el-dialog__body {
        padding: 10px !important;
        padding-bottom: 0px !important;
        /*height: 100% !important;*/
    }
    .el-dialog__footer{
        padding-top: 3px !important;
    }
    /*去除掉drawer的滚动条，让其内部产生滚动条即可，比如图片资源管理模块*/
    .el-drawer__body{
        overflow: hidden !important;
    }
</style>
