
const ip_local='127.0.0.1';//本机
const ip_test='192.168.7.131';//测试
const ip_product='192.168.7.131';//生产


// const type='local';
// const type='test';
const type='product';

const title='400客户档案查询平台';

let config = {
    //系统访问上下文，在生产模式下，需要和后台的server.servlet.context-path配置保持一致
    contextPath:'/customer400',
    title:title,
    baseURL:type=='local'?('http://'+ip_local+':7788')
        :(
            type=='test'?
                ('http://'+ip_test+':8006')
                :
                ('https://400.suxiangkj.com')
        ),
    //为app实例绑定全局属性（main和dialog处创建出实例之后会调用）
    bindAppProps:(app,props)=>{
        props.forEach(item=>{
            app.config.globalProperties[item.key] = item.value;
        });
    }
}
module.exports=config;